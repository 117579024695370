// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'bootstrap'
import 'inputmask'
import 'channels'

import './modules/common/jquery-maskmoney.js'
import './modules/common/input-mask.js'

import '../navigation/header/index.js'
import '../navigation/sidenav/index.js'

import '../admins/borrowers/index.js'

import '../admins/default_costs/edit/index.js'

import '../admins/lenders/edit/index.js'
import '../admins/lenders/index/index.js'

import '../admins/loans/borrower_info/index.js'
import '../admins/loans/closing_costs_modal/index.js'
import '../admins/loans/declarations_info/index.js'
import '../admins/loans/ethnicity_info/index.js'
import '../admins/loans/income_info/index.js'
import '../admins/loans/lending_options_modal/index.js'
import '../admins/loans/own_or_rent_info/index.js'
import '../admins/loans/race_info/index.js'
import '../admins/loans/race_options_modal/index.js'
import '../admins/loans/rerun_pricing_modal/index.js'
import '../admins/loans/sex_info/index.js'
import '../admins/loans/transaction_contacts_card/index.js'
import '../admins/loans/view_application_card/index.js'
import '../admins/loans/show/index.js'

import '../consumers/owned_properties/edit/index.js'
import '../consumers/owned_properties/new/index.js'
import '../consumers/owned_properties/verify_costs/index.js'
import '../consumers/owned_properties/verify_loans/index.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// DONE:
// admins/default_costs/index
// admins/lenders/edit
// admins/lenders/index
// admins/loans/borrower_info
// admins/loans/closing_costs_modal
// admins/loans/declarations_info
// admins/loans/ethnicity_info
// admins/loans/income_info
// admins/loans/lending_options_modal
// admins/loans/own_or_rent_info
// admins/loans/race_info
// admins/loans/race_options_modal
// admins/loans/rerun_pricing_modal
// admins/loans/sex_info
// admins/loans/transaction_contacts_card
// admins/loans/view_application_card
// admins/loans/show

// NOT DONE:
// admins/todos/todos_card

// consumers/borrowers/citizenship
// consumers/borrowers/dependents
// consumers/borrowers/marital_status
// consumers/borrowers/more_income
// consumers/borrowers/more_properties

// consumers/consumers/add_more_assets
// consumers/consumers/verify_assets_automatically
// consumers/consumers/verify_assets_manually
// consumers/consumers/verify_assets

// consumers/current_addresses/previous_address_content
// consumers/current_addresses/edit
// consumers/current_addresses/housing_expenses
// consumers/current_addresses/mailing_address_confirmation
// consumers/current_addresses/new
// consumers/current_addresses/own_or_rent

// consumers/declarations/declarations_continued
// consumers/declarations/declarations_start

// consumers/demographics/ethnicity
// consumers/demographics/race
// consumers/demographics/sex

// consumers/consumers/employers/edit
// consumers/consumers/employers/new

// consumers/loans/deprecated_sections
// consumers/loans/loan_options_modal
// consumers/loans/term_options_modal
// consumers/loans/todos
// consumers/loans/get_started
// consumers/loans/home_search_status
// consumers/loans/lender_compare
// consumers/loans/loan_purpose
// consumers/loans/old_lender_compare
// consumers/loans/refi_goal

// consumers/owned_properties/edit
// consumers/owned_properties/new
// consumers/owned_properties/verify_cost

// consumers/previous_addresses/previous_address_content
// consumers/previous_addresses/new

// consumers/soft_credit/index

// consumers/subject_properties/add_subject_property_modal
// consumers/subject_properties/subject_propery_cost_modal
// consumers/subject_properties/subject_propery_use_modal
// consumers/subject_properties/edit
// consumers/subject_properties/new
// consumers/subject_properties/property_costs
// consumers/subject_properties/property_use
// consumers/subject_properties/property_value
// consumers/subject_properties/verify_property_costs

// consumers/todos

// consumers/users/va_status

// consumers/shared/geocoder

// users/authorization_code

// users/invitations/edit

// users/registrations/live_registration
// users/registrations/edit
// users/registrations/new
