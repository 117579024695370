$(function () {
  $("#ethnicityQuestions").hide();
  $('#otherEthnicityInput').hide();

  $('#hispanic_or_latino').click(function() {
    if (this.checked) {
      $("#ethnicityQuestions").show();
      $('#ethnicity_not_provided').prop('checked', false);
    } else {
      $('#mexican, #puerto_rican, #cuban, #other').prop('checked', false);
      $("#ethnicityQuestions").hide();
      $('#otherEthnicityInput').hide();
      $('#ethnicityOrigin').prop('required', false);
      $('#ethnicityOrigin').val('');
    }
  });

  $('#not_hispanic_or_latino').click(function() {
    if (this.checked) {
      $('#ethnicity_not_provided').prop('checked', false);
    }
  });

  $('#other').click(function() {
    if (this.checked) {
      $('#otherEthnicityInput').show();
      $('#ethnicityOrigin').prop('required', true);
    } else {
      $('#otherEthnicityInput').hide();
      $('#ethnicityOrigin').val('');
      $('#ethnicityOrigin').prop('required', false);
    }
  });

  $('#ethnicity_not_provided').click(function() {
    if (this.checked) {
      $('#hispanic_or_latino, #not_hispanic_or_latino, #mexican, #puerto_rican, #cuban, #other').prop('checked', false);
      $("#questions").hide();
      $('#otherEthnicityInput').hide();
      $('#ethnicityOrigin').val('');
      $('#ethnicityOrigin').prop('required', false);
    }
  });
});