$(function () {
  $('#d_yes').on('click', function () {
    $('#d_yes').addClass('active');
    $('#d_no').removeClass('active');
  });

  $('#d_no').on('click', function () {
    $('#d_no').addClass('active');
    $('#d_yes').removeClass('active');
  });
});
