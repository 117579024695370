$(function () {
  $(".asianQuestions").hide();
  $(".hawaiianOrPiQuestions").hide();
  $('#otherRaceInput').hide();

  $('#native_american_or_alaska_native').click(function() {
    if (this.checked) {
      $('#otherRaceInput').show();
      $('#enrolled_or_principle_tribe').prop('required',true);
      clearRaceNotProvidedInputs();
    } else {
      clearNativeAmericanOrAlaskaNativeInputs();
    }
  });

  $('#asian').click(function() {
    if (this.checked) {
      $(".asianQuestions").show();
      clearRaceNotProvidedInputs();
    } else {
      clearAsianInputs();
    }
  });

  $('#black_or_african_american').click(function() {
    if (this.checked) {
      clearRaceNotProvidedInputs();
    } 
  });

  $('#native_hawaiian_or_pacific_islander').click(function() {
    if (this.checked) {
      $(".hawaiianOrPiQuestions").show();
      clearRaceNotProvidedInputs();
    } else {
      clearNativeHawaiianOrPacificIslanderInputs();
    }
  });

  $('#white').click(function() {
    if (this.checked) {
      clearRaceNotProvidedInputs();
    } 
  });

  $('#race_not_provided').click(function() {
    if (this.checked) {
      clearNativeAmericanOrAlaskaNativeInputs();
      clearAsianInputs();
      clearBlackOrAfricanAmericanInputs();
      clearNativeHawaiianOrPacificIslanderInputs();
      clearWhiteInputs();
    }
  });
});

function clearNativeAmericanOrAlaskaNativeInputs() {
  $('#native_american_or_alaska_native').prop('checked', false);
  $('#otherRaceInput').hide();
  $('#enrolled_or_principle_tribe').val('');
  $('#enrolled_or_principle_tribe').prop('required',false);
}

function clearAsianInputs() {
  $(".asianQuestions").hide();
  $('#asian, #asianIndian, #chinese, #filipino, #japanese, #korean, #vietnamese, #otherAsian').prop('checked', false);
}

function clearBlackOrAfricanAmericanInputs() {
  $('#black_or_african_american').prop('checked', false);
}

function clearNativeHawaiianOrPacificIslanderInputs() {
  $(".hawaiianOrPiQuestions").hide();
  $('#native_hawaiian_or_pacific_islander, #native_hawaiian, #guamanian_or_chamorro, #samoan, #other_pacific_islander').prop('checked', false);
}

function clearWhiteInputs() {
  $('#white').prop('checked', false);
}

function clearRaceNotProvidedInputs() {
  $('#race_not_provided').prop('checked', false);
}