$(function () {

  // FUNCTIONS
  function setpropertyOwnershipOptions() {
    if ($('#aa_yes').hasClass('active')) {
      $("#propertyOwnershipOptions").show();
    } else {
      $("#propertyOwnershipOptions").hide();
    }
  }

  $(function() {
    $('#estimated-purchase-price').maskMoney({prefix: '$'});
  })

  function yesButtonClick(event){
    var yesId = event.data.id + '_yes'
    var noId = event.data.id + '_no'
    
    $(yesId).addClass('active');
    $(noId).removeClass('active');  
  } 

  function noButtonClick(event) {
    var yesId = event.data.id + '_yes'
    var noId = event.data.id + '_no'

    $(noId).addClass('active');
    $(yesId).removeClass('active');    
  };
  
  // HANDLERS
  $('#a_yes').click(function() {
    $("#aa").show();
    setpropertyOwnershipOptions();
  });
  $("#a_yes").click({id: '#a'}, yesButtonClick);

  $('#a_no').click(function() {
    console.log("Clicked a_no button...")
    $("#aa").hide();
  });
  $("#a_no").click({id: '#a'}, noButtonClick);

  $('#aa_yes').click(function() {
    $("#propertyOwnershipOptions").show();
  });
  $("#aa_yes").click({id: '#aa'}, yesButtonClick);

  $('#aa_no').click(function() {
    $("#propertyOwnershipOptions").hide();
  });
  $("#aa_no").click({id: '#aa'}, noButtonClick);

  $("#b_yes").click({id: '#b'}, yesButtonClick);

  $("#b_no").click({id: '#b'}, noButtonClick);

  $('#c_yes').click(function() {
    $("#borrowingMoneyNotDisclosedAmount").show();
    $("#estimated-purchase-price").prop('required',true);
  });
  $("#c_yes").click({id: '#c'}, yesButtonClick);

  $('#c_no').click(function() {
    $("#borrowingMoneyNotDisclosedAmount").hide();
    $('#estimated-purchase-price').removeAttr("required");
  });
  $("#c_no").click({id: '#c'}, noButtonClick);

  $("#d1_yes").click({id: '#d1'}, yesButtonClick);

  $("#d1_no").click({id: '#d1'}, noButtonClick);

  $("#d2_yes").click({id: '#d2'}, yesButtonClick);

  $("#d2_no").click({id: '#d2'}, noButtonClick);

  $("#e_yes").click({id: '#e'}, yesButtonClick);

  $("#e_no").click({id: '#e'}, noButtonClick);

  $('#f_yes').click(function() {
    $("#ff").show();
    $("#borrower_declaration_f_explanation").prop('required',true);
  });
  $("#f_yes").click({id: '#f'}, yesButtonClick);

  $('#f_no').click(function() {
    $("#ff").hide();
    $('#borrower_declaration_f_explanation').removeAttr("required");
  });
  $("#f_no").click({id: '#f'}, noButtonClick);

  $('#g_yes').click(function() {
    $("#gg").show();
    $("#borrower_declaration_g_explanation").prop('required',true);
  });
  $("#g_yes").click({id: '#g'}, yesButtonClick);

  $('#g_no').click(function() {
    $("#gg").hide();
    $('#borrower_declaration_g_explanation').removeAttr("required");
  });
  $("#g_no").click({id: '#g'}, noButtonClick);

  $('#h_yes').click(function() {
    $("#hh").show();
    $("#borrower_declaration_h_explanation").prop('required',true);
  });
  $("#h_yes").click({id: '#h'}, yesButtonClick);

  $('#h_no').click(function() {
    $("#hh").hide();
    $('#borrower_declaration_h_explanation').removeAttr("required");
  });
  $("#h_no").click({id: '#h'}, noButtonClick);

  $('#i_yes').click(function() {
    $("#ii").show();
    $("#borrower_declaration_i_explanation").prop('required',true);
  });
  $("#i_yes").click({id: '#i'}, yesButtonClick);

  $('#i_no').click(function() {
    $("#ii").hide();
    $('#borrower_declaration_i_explanation').removeAttr("required");
  });
  $("#i_no").click({id: '#i'}, noButtonClick);

  $('#j_yes').click(function() {
    $("#jj").show();
    $("#borrower_declaration_j_date").prop('required',true);
    $("#borrower_declaration_j_explanation").prop('required',true);
  });
  $("#j_yes").click({id: '#j'}, yesButtonClick);

  $('#j_no').click(function() {
    $("#jj").hide();
    $('#borrower_declaration_j_date').removeAttr("required");
    $('#borrower_declaration_j_explanation').removeAttr("required");
  });
  $("#j_no").click({id: '#j'}, noButtonClick);

  $('#k_yes').click(function() {
    $("#kk").show();
    $("#borrower_declaration_k_date").prop('required',true);
    $("#borrower_declaration_k_explanation").prop('required',true);
  });
  $("#k_yes").click({id: '#k'}, yesButtonClick);

  $('#k_no').click(function() {
    $("#kk").hide();
    $('#borrower_declaration_k_date').removeAttr("required");
    $('#borrower_declaration_k_explanation').removeAttr("required");
  });
  $("#k_no").click({id: '#k'}, noButtonClick);

  $('#l_yes').click(function() {
    $("#ll").show();
    $("#borrower_declaration_l_date").prop('required',true);
    $("#borrower_declaration_l_explanation").prop('required',true);
  });
  $("#l_yes").click({id: '#l'}, yesButtonClick);

  $('#l_no').click(function() {
    $("#ll").hide();
    $('#borrower_declaration_l_date').removeAttr("required");
    $('#borrower_declaration_l_explanation').removeAttr("required");
  });
  $("#l_no").click({id: '#l'}, noButtonClick);

  $('#m_yes').click(function() {
    $("#mm").show();
    $("#borrower_declaration_m_date").prop('required',true);
    $("#borrower_declaration_m_explanation").prop('required',true);
  });
  $("#m_yes").click({id: '#m'}, yesButtonClick);

  $('#m_no').click(function() {
    $("#mm").hide();
    $('#borrower_declaration_m_date').removeAttr("required");
    $('#borrower_declaration_chapterSeven, #borrower_declaration_chapterEleven, #borrower_declaration_chapterTwelve, #borrower_declaration_chapterThirteen').prop('checked', false);
    $("#borrower_declaration_m_explanation").prop('required',false);
  });
  $("#m_no").click({id: '#m'}, noButtonClick);

  // DOM
  if ($('#a_yes').hasClass('active')) {
    $("#aa").show();
    setpropertyOwnershipOptions();    
  } else {
    $("#aa").hide();
  }

  if ($('#c_yes').hasClass('active')) {
    $("#borrowingMoneyNotDisclosedAmount").show();
  } else {
    $("#borrowingMoneyNotDisclosedAmount").hide();
  }

  if ($('#f_yes').hasClass('active')) {
    $("#ff").show();
    $("#borrower_declaration_f_explanation").prop('required',true);
  } else {
    $("#ff").hide();
    $('#borrower_declaration_f_explanation').removeAttr("required");
  }

  if ($('#g_yes').hasClass('active')) {
    $("#gg").show();
    $("#borrower_declaration_g_explanation").prop('required',true);
  } else {
    $("#gg").hide();
    $('#borrower_declaration_g_explanation').removeAttr("required");
  }

  if ($('#h_yes').hasClass('active')) {
    $("#hh").show();
    $("#borrower_declaration_h_explanation").prop('required',true);  
  } else {
    $("#hh").hide();
    $('#borrower_declaration_h_explanation').removeAttr("required");
  }

  if ($('#i_yes').hasClass('active')) {
    $("#ii").show();
    $("#borrower_declaration_i_explanation").prop('required',true);
  } else {
    $("#ii").hide();
    $('#borrower_declaration_i_explanation').removeAttr("required");
  }

  if ($('#j_yes').hasClass('active')) {
    $("#jj").show();
    $("#borrower_declaration_j_explanation").prop('required',true);
    $("#borrower_declaration_j_date").prop('required',true); 
  } else {
    $("#jj").hide();
    $('#borrower_declaration_j_explanation').removeAttr("required");
    $('#borrower_declaration_j_date').removeAttr("required");
  }

  if ($('#k_yes').hasClass('active')) {
    $("#kk").show();
    $("#borrower_declaration_k_explanation").prop('required',true); 
    $("#borrower_declaration_k_date").prop('required',true); 
  } else {
    $("#kk").hide();
    $('#borrower_declaration_k_explanation').removeAttr("required");
    $('#borrower_declaration_k_date').removeAttr("required");
  }

  if ($('#l_yes').hasClass('active')) {
    $("#ll").show();
    $("#borrower_declaration_l_explanation").prop('required',true); 
    $("#borrower_declaration_l_date").prop('required',true); 
  } else {
    $("#ll").hide();
    $('#borrower_declaration_l_explanation').removeAttr("required");
    $('#borrower_declaration_l_date').removeAttr("required");
  }

  if ($('#m_yes').hasClass('active')) {
    $("#mm").show();
    $("#borrower_declaration_m_explanation").prop('required',true); 
    $("#borrower_declaration_m_date").prop('required',true); 
  } else {
    $("#mm").hide();
    $('#borrower_declaration_m_explanation').removeAttr("required");
    $('#borrower_declaration_m_date').removeAttr("required");
  }
});