$(function () {
  $('#allLoans').on('click', function () {
    $('#allLoans').addClass('underlined');
    $('#priorities, #underwriting, #closing, #myFiles').removeClass('underlined');
  });

  $('#priorities').on('click', function () {
    $('#allLoans, #underwriting, #closing, #myFiles').removeClass('underlined');
    $('#priorities').addClass('underlined');
  });

  $('#underwriting').on('click', function () {
    $('#allLoans, #priorities, #closing, #myFiles').removeClass('underlined');
    $('#underwriting').addClass('underlined');
  });

  $('#closing').on('click', function () {
    $('#allLoans, #priorities, #underwriting, #myFiles').removeClass('underlined');
    $('#closing').addClass('underlined');
  });
});