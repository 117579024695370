$(function () {
  $('.transaction-contacts-modal-footer').hide();
  $('.contactField').hide();
  $('.lenderDropdown').hide();

  $('#transaction_contact_type').change(function() {
    var value = $(this).val();

    $('.transaction-contacts-modal-footer').show();

    if (value == 'Buyer Agent' || value == 'Buyer Attorney' || value == 'Title Company') {
      $('.transaction-contacts-modal-footer, .contactField').show();
      $('.lenderDropdown').hide();
      $('#transaction_contact_lender_types').val('');
    } else {
      $('.contactField, .lenderDropdown, .transaction-contacts-modal-footer').hide();
    }

    if (value == '') {
      $('.contactField, .lenderDropdown, .transaction-contacts-modal-footer').hide();
      $('#transaction_contact_lender_types').val('');
    }
    
    if (value == 'Lender') {
      $('.lenderDropdown').show();
    }
  });

  $('#transaction_contact_lender_types').change(function() {
    var value = $(this).val();

    if (value == 'AE') {
      $('.contactField').show();
      $('.transaction-contacts-modal-footer').show();
    }

    if (value == 'Processor') {
      $('.contactField').show();
      $('.transaction-contacts-modal-footer').show();
    }
    
    if (value == 'Underwritter') {
      $('.contactField').show();
      $('.transaction-contacts-modal-footer').show();
    }

    if (value == '') {
      $('.contactField').hide();
      $('.transaction-contacts-modal-footer').hide();
    }
  });

  $('#transactionContactsCloseBtn').on('click', function () {
    $('#transaction_contact_type, #transaction_contact_lender_types, #company_name, #first_name, #last_name, #telephone-input, #email').val(null);
    $('.lenderDropdown, .contactField, .transaction-contacts-modal-footer').hide();
  });
});