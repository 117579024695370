$(function () {
  $('.thirty-button').on('click', function () {
    $('.thirty-button').toggleClass('active');
  });

  $('.twenty-five-button').on('click', function () {
    $('.twenty-five-button').toggleClass('active');
  });

  $('.twenty-button').on('click', function () {
    $('.twenty-button').toggleClass('active');
  });

  $('.fifteen-button').on('click', function () {
    $('.fifteen-button').toggleClass('active');
  });

  $('.ten-button').on('click', function () {
    $('.ten-button').toggleClass('active');
  });

  $('.other-term-button').on('click', function () {
    $('.other-term-button').toggleClass('active');
  });

  $('.conventional-button').on('click', function () {
    $('.conventional-button').toggleClass('active');
  });

  $('.fha-button').on('click', function () {
    $('.fha-button').toggleClass('active');
  });

  $('.va-button').on('click', function () {
    $('.va-button').toggleClass('active');
  });

  $('.jumbo-button').on('click', function () {
    $('.jumbo-button').toggleClass('active');
  });

  $('.other-program-button').on('click', function () {
    $('.other-program-button').toggleClass('active');
  });
});