$(function () {
  $('#documentTitle, #documentDescription, #documentFile, #notes, .documents-modal-footer').hide();
  $('#allTodosFilter').addClass('underlined');
  $('#allDocumentsFilter').addClass('underlined');
  $('#allTodos').addClass('active-filter');
  $('#allDocuments').addClass('active-filter');

  $('#allDocuments').on('click', function () {
    $('#allDocumentsFilter').addClass('underlined');
    $('#incomeDocumentsFilter, #assetsDocumentsFilter, #propertyDocumentsFilter, #otherDocumentsFilter').removeClass('underlined');
    $('#allDocuments').addClass('active-filter');
    $('#incomeDocuments, #assetsDocuments, #propertyDocuments, #otherDocuments').removeClass('active-filter');
  });

  $('#incomeDocuments').on('click', function () {
    $('#incomeDocumentsFilter').addClass('underlined');
    $('#allDocumentsFilter, #assetsDocumentsFilter, #propertyDocumentsFilter, #otherDocumentsFilter').removeClass('underlined');
    $('#incomeDocuments').addClass('active-filter');
    $('#allDocuments, #assetsDocuments, #propertyDocuments, #otherDocuments').removeClass('active-filter');
  });

  $('#assetsDocuments').on('click', function () {
    $('#assetsDocumentsFilter').addClass('underlined');
    $('#allDocumentsFilter, #incomeDocumentsFilter, #propertyDocumentsFilter, #otherDocumentsFilter').removeClass('underlined');
    $('#assetsDocuments').addClass('active-filter');
    $('#allDocuments, #incomeDocuments, #propertyDocuments, #otherDocuments').removeClass('active-filter');
  });

  $('#propertyDocuments').on('click', function () {
    $('#propertyDocumentsFilter').addClass('underlined');
    $('#allDocumentsFilter, #incomeDocumentsFilter, #assetsDocumentsFilter, #otherDocumentsFilter').removeClass('underlined');
    $('#propertyDocuments').addClass('active-filter');
    $('#allDocuments, #incomeDocuments, #assetDocuments, #otherDocuments').removeClass('active-filter');
  });

  $('#otherDocuments').on('click', function () {
    $('#otherDocumentsFilter').addClass('underlined');
    $('#allDocumentsFilter, #incomeDocumentsFilter, #assetsDocumentsFilter, #propertyDocumentsFilter').removeClass('underlined');
    $('#otherDocuments').addClass('active-filter');
    $('#allDocuments, #incomeDocuments, #assetDocuments, #propertyDocuments').removeClass('active-filter');
  });

  $('#document_status').change(function() {
    var value = $(this).val();

    if (value == 'Income' || value == 'Assets' || value == 'Property' || value == 'Other') {
      $('#documentTitle, #documentDescription, #documentFile, #notes, .documents-modal-footer').show();
    } else {
      $('#documentTitle, #documentDescription, #documentFile, #notes, .documents-modal-footer').hide();
      $('#title, #description, #document_file, #notes').val(null);
    }
  });

  $('#addDocumentBtnClose').on('click', function () {
    $('#document_status, #document_title, #document_description, #document_file').val(null);
    $('#documentTitle, #documentDescription, #documentFile, .documents-modal-footer').hide();
  });
});