$(function () {
  $('.sold-button').on('click', function () {
    $('.sold-button').addClass('active');
    $('.pending-button').removeClass("active");
    $('.retained-button').removeClass('active');
  });
  
  $('.pending-button').on('click', function () {
    $('.pending-button').addClass('active');
    $('.sold-button').removeClass("active");
    $('.retained-button').removeClass('active');
  });

  $('.retained-button').on('click', function () {
    $('.retained-button').addClass('active');
    $('.sold-button').removeClass("active");
    $('.pending-button').removeClass("active");
  });

  $('.primary-button').on('click', function () {
    $('.primary-button').addClass('active');
    $('.secondary-button').removeClass("active");
    $('.investment-button').removeClass("active");
  });

  $('.secondary-button').on('click', function () {
    $('.secondary-button').addClass('active');
    $('.primary-button').removeClass("active");
    $('.investment-button').removeClass("active");
  });

  $('.investment-button').on('click', function () {
    $('.investment-button').addClass('active');
    $('.primary-button').removeClass("active");
    $('.secondary-button').removeClass("active");
  });

  $(function() {
    $('#estimatedValue').maskMoney();
  });
});