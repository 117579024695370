$(function () {
  $('.moneyAmt').maskMoney({prefix: '$'});

  $("#loanAppForm").submit(function () {
    var textboxes = $('.moneyAmt');
    $.each(textboxes, function(index, item) {
        var text = $(this).val().replace(/[$,]/g, ''); // remove characters
        $(this).val(text); // update value
    });
  });
});