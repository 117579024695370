$(function () {
$('.own-or-rent-yes-button').on('click', function () {
    $('.own-or-rent-yes-button').addClass('active');
    $('.own-or-rent-no-button').removeClass("active");
    $('.own-or-rent-neither-button').removeClass('active');
    $('#rentPayment').hide();
    $('#monthlyRent').prop('required', false);
    $('#monthlyRent').val('');
    $('.own-option').val(true);
    $('.own-option').prop('required',true);
    $('.rent-option').prop('required',false);
    $('.family-option').prop('required',false);
  });
  
  $('.own-or-rent-no-button').on('click', function () {
    $('.own-or-rent-no-button').addClass('active');
    $('.own-or-rent-yes-button').removeClass("active");
    $('.own-or-rent-neither-button').removeClass('active');
    $('#rentPayment').show();
    $('#monthlyRent').prop('required', true);
    $('.rent-option').val(true);
    $('.rent-option').prop('required',true);
    $('.own-option').prop('required',false);
    $('.family-option').prop('required',false);
  });

  $('.own-or-rent-neither-button').on('click', function () {
    $('.own-or-rent-neither-button').addClass('active');
    $('.own-or-rent-no-button').removeClass('active');
    $('.own-or-rent-yes-button').removeClass("active");
    $('#rentPayment').hide();
    $('#monthlyRent').prop('required', false);
    $('#monthlyRent').val('');
    $('.family-option').val(true);
    $('.family-option').prop('required',true);
    $('.own-option').prop('required',false);
    $('.rent-option').prop('required',false);
  });

  $('#monthlyRent').maskMoney({prefix: '$'});
 
});