$(function() {
  $('#adminFee').maskMoney({prefix: '$'});
  $('#brokerCompensation').maskMoney({prefix: '$'});
  $('#appraisalFee').maskMoney({prefix: '$'});
  $('#creditReport').maskMoney({prefix: '$'});
  $('#taxAndGov').maskMoney({prefix: '$'});
  $('#totalTitleCost').maskMoney({prefix: '$'});
  $('#homeownersIns').maskMoney({prefix: '$'});
  $('#prepaidInterest').maskMoney({prefix: '$'});
  $('#hoiDueAtClosing').maskMoney({prefix: '$'});
  $('#taxDueAtClosing').maskMoney({prefix: '$'});
  $('#totalClosingCosts').maskMoney({prefix: '$'});
});