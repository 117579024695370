$(function () {
  // $("body").addClass("loading");

  // let navState = localStorage.getItem('sb|sidebar-toggle');
  // let dashBody = $('.dashboard-body');

  // if (navState === 'true') {
  //   dashBody.addClass('sb-sidenav-toggled')
  //   setTimeout(function() { 
  //     $("body").removeClass("loading"); 
  //   }, 250);
  // } else {
  //   dashBody.removeClass('sb-sidenav-toggled')
  //   setTimeout(function() { 
  //     $("body").removeClass("loading"); 
  //   }, 250);
  // }

  $('#sidebarToggle').on('click', function (e) {
    e.preventDefault();
    document.body.classList.toggle('sb-sidenav-toggled');
    localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));

    let navState = localStorage.getItem('sb|sidebar-toggle');
    let dashBody = $('.dashboard-body');

    if (navState === 'true') {
      dashBody.addClass('sb-sidenav-toggled')
    } else {
      dashBody.removeClass('sb-sidenav-toggled')
    }
  });

  $('.logout').on('click', function (e) {
    e.preventDefault();
    localStorage.clear();
    dashBody.removeClass('sb-sidenav-toggled');
  });
});