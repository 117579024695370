$(function () {
  $('.taxes-included-yes').on('click', function () {
    $('.taxes-included-yes').addClass('active');
    $('.taxes-included-no').removeClass("active");
  });

  $('.taxes-included-no').on('click', function () {
    $('.taxes-included-no').addClass('active');
    $('.taxes-included-yes').removeClass("active");
  });

  $(function() {
    $('#yearlyTaxes').maskMoney({prefix: '$'});
    $('#yearlyInsurance').maskMoney({prefix: '$'});
    $('#yearlyIncome').maskMoney({prefix: '$'});
  })
});