$(function () {
  $('#male').click(function() {
    if (this.checked) {
      clearSexNotProvidedInputs();
    }
  });

  $('#female').click(function() {
    if (this.checked) {
      clearSexNotProvidedInputs();
    }
  });

  $('#sex_not_provided').click(function() {
    if (this.checked) {
      $('#male').prop('checked', false);
      $('#female').prop('checked', false);
    }
  });
});

function clearSexNotProvidedInputs() {
  $('#sex_not_provided').prop('checked', false);
}